import gql from "graphql-tag";

const paginatedContent = `
  total
  cursor
  page {
    id
    network
    date
    text
    flow
    sender
    recipient
    agentId
    toxicity
    safety
    illustration
    profileImage
    checkError
    action
    matches
    isPrivate
    groupId
    emotion {
      winner
      score
      bayesPrediction
      bayesProba
    }
    training {
      label
      at
    }
  }
`;

export const queryContent = gql`
  query getContent($network: [Network]!, $flow: [Direction]!, $cursor: String, $groupId: String) {
    content(network: $network, flow: $flow, cursor: $cursor, groupId: $groupId) {
      ${paginatedContent}
    }
  }
`;

export const queryAgentContent = gql`
query agentContent($network: [Network]!, $flow: [Direction]!, $cursor: String, $agentId: String!) {
  agentContent(network: $network, flow: $flow, cursor: $cursor, agentId: $agentId) {
    ${paginatedContent}
  }
}
`;

export const queryVerifyPasswordResetToken = gql`
  query verifyPasswordResetToken($token: String!, $id: String!) {
    verifyPasswordResetToken(token: $token, id: $id) {
      valid
    }
  }
`;

export const queryVerifyEmail = gql`
  query verifyEmail($id: String!, $token: String!) {
    verifyEmail(id: $id, token: $token) {
      id
      token
      verifyEmail
    }
  }
`;

export const mutateSignIn = gql`
  mutation userLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      email
      verifyEmail
    }
  }
`;

export const mutateSignUp = gql`
  mutation userSignUp(
    $email: String!
    $password: String!
    $last: String!
    $first: String!
    $mobile: String
    $domain: String!
  ) {
    signup(
      email: $email
      password: $password
      first: $first
      last: $last
      mobile: $mobile
      domain: $domain
    ) {
      id
      email
    }
  }
`;

export const mutateRequestPasswordReset = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const mutatePasswordReset = gql`
  mutation passwordReset($password: String!, $token: String!, $id: String!) {
    passwordReset(password: $password, token: $token, id: $id)
  }
`;

export const mutateResendVerifyEmail = gql`
  mutation resendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email) {
      id
      verifyEmail
    }
  }
`;

export const mutateSubscription = gql`
  mutation subscribe($plan: String!, $token: String!, $coupon: String) {
    subscribe(plan: $plan, token: $token, coupon: $coupon) {
      id
      token
      email
    }
  }
`;

export const mutateInviteMember = gql`
  mutation users(
    $first: String!
    $last: String!
    $email: String!
    $dateOfBirth: String!
    $mobile: String
    $group: String!
    $agreement: String
  ) {
    memberInvite(
      first: $first
      last: $last
      email: $email
      dateOfBirth: $dateOfBirth
      mobile: $mobile
      group: $group
      agreement: $agreement
    ) {
      user { email, firstName, lastName}
      existsInDatabase
      existsInGroup
    }
  }
`;

export const querySubscriptions = gql`
  query userQuery {
    name
    seats
  }
`;

export const userMember = gql`
  query userMember($id: String!) {
    userMember(id: $id) {
      id
      firstName
      lastName
      email
      mobile
      member {
        id
        twitter_connected
        instagram_connected
      }
    }
  }
`;

export const queryGroup = gql`
  query group($groupId: String!) {
    group(groupId: $groupId) {
      name
      administrators {
        id
        firstName
        lastName
        email
        mobile
        externalLogins {
          unUnencrypted
          pwUnencrypted
          unEncrypted
          pwEncrypted
        }
      }
      members {
        id
        firstName
        lastName
        email
        mobile
        dateOfBirth
        connections
        externalLogins {
          unUnencrypted
          pwUnencrypted
          unEncrypted
          pwEncrypted
        }
      }
    }
  }
`;

export const queryGroups = gql`
  query {
    groups {
      id
      name
      services {
        type
        name
        scope
        logo
        props {
          appleId
          playStoreId
          streamUrl
          isLive
          generalDataProperty
        }
        interaction
        value
      }
      administrators {
        id
        firstName
        lastName
        email
        mobile
        externalLogins {
          unUnencrypted
          pwUnencrypted
          unEncrypted
          pwEncrypted
        }
      }
      members {
        id
        firstName
        lastName
        email
        mobile
        dateOfBirth
        connections
        externalLogins {
          unUnencrypted
          pwUnencrypted
          unEncrypted
          pwEncrypted
        }
      }
    }
  }
`;

export const queryPlans = gql`
  query {
    plans {
      id
      name
      seats
      cost
      features
      interval
      sites
    }
  }
`;

export const queryTeamNotifications = gql`
  query queryTeamNotifications($team: String!) {
    teamNotifications(team: $team) {
      title
      body
      at
    }
  }
`;

const rule = `
  id
  domain
  active
  description
  trigger
  action
  canEdit
  templates {
    id
    sms
    push
    email
  }
  notifications {
    userid
    mobile
    sms
    email
  }
  triggerConfig {
    toTime
    fromTime
    nudity
    tensor
    fromDate
    toDate
    allDay
    repeatMonthly
    timezone
    wordListId
  } 
  llmConfig {
    llmName
    triggerType
    projectId
    apiKey
    serviceUrl
    modelId
  }
 
`;

export const queryRules = gql`
  query group($groupId: String!) {
    group(groupId: $groupId) {
      name
      administrators {
        id
        firstName
        lastName
        email
        mobile
      }
      members {
        id
        firstName
        lastName
        email
        mobile
        dateOfBirth
        connections
      }
    }
    wordList {
      id
      name
    }
    rules(groupId: $groupId) {
      ${rule}
    }
  }
`;

export const findRulesWithDescription = gql`
  query findRulesWithDescription($query: String!, $groupId: String!) {
    findRulesWithDescription(query: $query, groupId: $groupId) {
      id
      enabledForTeam
      description
      active
    }
  }
`;

export const getRules = gql`
  query rules($groupId: String!) {
    rules(groupId: $groupId) {
      id
      enabledForTeam
      description
      active
    }
  }
`;

export const mutateRule = gql`
  mutation rule($rule: RuleInput!) {
    rule(rule: $rule) {
      ${rule}
    }
  }
`;

export const mutateToggleRule = gql`
  mutation toggleRule($id: String!) {
    toggleRule(id: $id) {
      ${rule}
    }
  }
`;

export const queryWordList = gql`
  query wordList {
    wordList {
      id
      name
    }
  }
`;

export const mutateWordList = gql`
  mutation createWordList($name: String!) {
    createWordList(name: $name) {
      id
      name
    }
  }
`;

export const queryWords = gql`
  query Words($listId: String!) {
    words(listId: $listId) {
      id
      word
    }
  }
`;

export const mutateAddWord = gql`
  mutation AddWord($listId: String!, $word: String!) {
    addWord(listId: $listId, word: $word) {
      id
      word
    }
  }
`;

export const mutateRemoveWord = gql`
  mutation RemoveWord($id: String!) {
    removeWord(id: $id)
  }
`;

export const mutateMedia = gql`
  mutation Media($action: String!, $id: String!) {
    media(action: $action, id: $id)
  }
`;

export const mutateMember = gql`
  mutation Member(
    $id: String!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: String!
    $mobile: String
    $externalLoginInput: [SafariMontageInput]
  ) {
    member(
      id: $id
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      mobile: $mobile
      externalLoginInput: $externalLoginInput
    ) {
      id
      firstName
      lastName
      dateOfBirth
      mobile
      externalLogins {
        unUnencrypted
        pwUnencrypted
        unEncrypted
        pwEncrypted
      }
    }
  }
`;

export const mutateMemberRemove = gql`
  mutation MemberRemove($id: String!, $group: String!) {
    memberRemove(id: $id, group: $group)
  }
`;

export const mutateConnectAgent = gql`
  mutation ConnectAgent($network: String!) {
    connectAgent(network: $network)
  }
`;

export const mutateAdmin = gql`
  mutation Admin(
    $id: String!
    $first: String
    $last: String!
    $mobile: String
    $externalLoginInput: [SafariMontageInput]
  ) {
    admin(
      id: $id
      first: $first
      last: $last
      mobile: $mobile
      externalLoginInput: $externalLoginInput
    ) {
      id
      firstName
      lastName
      mobile
      externalLogins {
        unUnencrypted
        pwUnencrypted
        unEncrypted
        pwEncrypted
      }
    }
  }
`;

export const mutateAdminRemove = gql`
  mutation Admin($id: String!, $group: String!) {
    adminRemove(id: $id, group: $group)
  }
`;

export const mutateInviteAdmin = gql`
  mutation Admin(
    $first: String!
    $last: String!
    $email: String!
    $mobile: String
    $group: String!
  ) {
    adminInvite(
      first: $first
      last: $last
      email: $email
      mobile: $mobile
      group: $group
    ) {
      id
      firstName
      lastName
      email
      mobile
    }
  }
`;

export const mutateAddAdmin = gql`
  mutation Admin($email: String!, $group: String!) {
    addAdmin(email: $email, group: $group)
  }
`;

export const mutateRemoveAdmin = gql`
  mutation Admin($id: String!, $group: String!) {
    removeAdmin(id: $id, group: $group)
  }
`;

export const mutateCreateGroup = gql`
  mutation Group($name: String!) {
    createGroup(name: $name)
  }
`;

export const mutateDeleteGroup = gql`
  mutation Group($id: String!) {
    deleteGroup(id: $id)
  }
`;

export const mutateSetServices = gql`
  mutation Services($group: String!, $services: [ServiceInput]) {
    setServices(group: $group, services: $services)
  }
`;

export const mutateCoupon = gql`
  mutation Coupon($coupon: String!) {
    couponVerify(coupon: $coupon) {
      amountOff
      discount
    }
  }
`;

export const mutateSetRuleForTeam = gql`
  mutation SetRuleForTeam($id: String!, $teamId: String!, $enabled: Boolean) {
    setRuleForTeam(id: $id, teamId: $teamId, enabled: $enabled) {
      active
    }
  }
`;

export const mutateRequestPrivateText = gql`
  mutation PrivateText(
    $password: String!
    $contentId: String!
    $groupId: String!
    $agentId: String!
  ) {
    privateText(
      password: $password
      contentId: $contentId
      groupId: $groupId
      agentId: $agentId
    )
  }
`;

export const mutatePaymentDetails = gql`
  mutation PaymentDetailsUpdate($token: String!, $group: String!) {
    paymentDetailsUpdate(token: $token, group: $group)
  }
`;

export const queryGroupPlan = gql`
  query groupPlan($group: String!) {
    groupPlan(group: $group)
    plans {
      id
      name
      seats
      cost
      features
      interval
      sites
    }
  }
`;

export const mutateSubscriptionCancel = gql`
  mutation SubscriptionCancel($group: String!) {
    subscriptionCancel(group: $group)
  }
`;

export const mutateUpdateSubscription = gql`
  mutation UpdateSubscription(
    $plan: String!
    $token: String!
    $coupon: String
    $group: String
  ) {
    updateSubscription(
      plan: $plan
      token: $token
      coupon: $coupon
      group: $group
    )
  }
`;

export const mutateReportIncident = gql`
  mutation ReportIncident(
    $name: String!
    $location: String!
    $date: String!
    $time: String!
    $description: String
  ) {
    reportIncident(
      name: $name
      location: $location
      date: $date
      time: $time
      description: $description
    )
  }
`;

export const mutateManualNotification = gql`
  mutation ManualNotification(
    $team: String
    $userID: String
    $title: String!
    $body: String!
  ) {
    createManualNotifications(
      team: $team
      userID: $userID
      title: $title
      body: $body
    )
  }
`;

// Application
export const checkUsername = gql`
  query checkUsername($username: String!) {
    checkUsername(username: $username) {
      username
      available
    }
  }
`;

// Application
export const checkApplicationToken = gql`
  query checkApplicationToken($token: String!) {
    checkApplicationToken(token: $token) {
      valid
    }
  }
`;

export const approveApplication = gql`
  query approveApplication($token: String!, $id: String, $agreement: String) {
    approveApplication(token: $token, id: $id, agreement: $agreement) {
      valid
    }
  }
`;

export const rejectApplication = gql`
  query rejectApplication($token: String!) {
    rejectApplication(token: $token) {
      valid
    }
  }
`;

export const memberApplication = gql`
  mutation memberApplication(
    $firstName: String!
    $lastName: String!
    $dateOfBirth: String!
    $username: String
    $email: String
    $password: String!
    $guardianName: String!
    $groupId: String
  ) {
    memberApplication(
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      username: $username
      email: $email
      password: $password
      guardianName: $guardianName
      groupId: $groupId
    )
  }
`;
