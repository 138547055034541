import React from 'react';
import Form from './Form';
import { Mutation } from 'react-apollo';
import { mutateRule, mutateToggleRule, queryRules } from './../../../utils/gql';
import { getGroup } from './../../../utils/account';

const removeTypename = obj => {
  if (obj instanceof Array) {
    obj.forEach(removeTypename);
  } else {
    delete obj.__typename;
    Object.keys(obj).forEach(k => {
      const v = obj[k];
      if (v && typeof v === 'object')
        removeTypename(v);
    });
  }
  return obj;
};

const clean = a =>
  removeTypename(JSON.parse(JSON.stringify(a)));

export default class extends React.Component {
  constructor() {
    super();
    this.state = { error: null };
  }
  onSave(mutate, item) {
    const { triggerConfig, trigger, templates, action, notifications, description, domain, llmConfig, usesLlm } = item;
    if (usesLlm || llmConfig) { 
        const unfilledOut = ['projectId', 'apiKey', 'serviceUrl'].filter(key => llmConfig[key] === '');
      if (unfilledOut.length > 0) {
        const error = `Following fields are required for LLM: ${unfilledOut.join(', ')}`;
        return this.setState({ error});
      }
    }
    const isNewItem =  item.id < 0;

    const groupId = getGroup().id || null;
    let triggerConfClean = { ...clean(triggerConfig) };
    delete triggerConfClean.disabled;
    if (!description || description === '') {
      return this.setState({ error: 'description is required.' });
    }
    mutate({
      variables: {
        rule: {
          id: item.id < 0 ? undefined : item.id,
          description,
          action,
          trigger,
          groupId: (item.groupId || groupId),
          templates: clean(templates),
          notifications: clean(notifications),
          ...triggerConfClean,
          domain,
          llmConfig: llmConfig ?  clean(llmConfig) : null,
        },
      },
      update: (store, { data: { rule: newRule } }) => {
        if (!item.groupId) return;
        const variables = { groupId: item.groupId };
        const data = store.readQuery({ query: queryRules, variables });
        const { rules } = data;
        rules.push(newRule);
        store.writeQuery({ query: queryRules, variables, data });
      },
    })
      .then(({ data }) => {
        const error = !(data.rule && data.rule.id) && 'description for rule must be unique.';
        this.setState({ error });
        if(error) return;
        if(isNewItem) {
          this.props.refresh();
        }
      });
  }
  onToggle(mutate, item) {
    const { onUpdate } = this.props;
    mutate({
      variables: {
        id: item.id,
      },
    }).then(({ data }) => onUpdate(data.toggleRule));
  }
  render() {
    const { error } = this.state;
    return (
      <Mutation mutation={mutateToggleRule}>{
        toggleRule => (
          <Mutation mutation={mutateRule}>{
            mutate => (
              <Form
                {...this.props}
                onSave={this.onSave.bind(this, mutate)}
                onToggle={this.onToggle.bind(this, toggleRule)}
                error={error}
              />
            )
          }</Mutation>
        )
      }</Mutation>
    );
  }
}
