import React from 'react';
import Edit from './Edit';
import { QueryHandler, Fa } from './../../../utils/common';
import { queryRules } from './../../../utils/gql';
import styled from 'react-emotion';
import { getGroup } from './../../../utils/account';

const Container = styled.div`
  display: flex;
`;

const Menu = styled.div`
  min-width: 250px !important;
`;

const A = styled.a`
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const NewRuleBtn = styled.button`
  width: 100%;
`;

const NoRuleSelected = () => (
  <div className="empty">
    <div className="empty-icon">
      <Fa fa="fa-book" xlarge={true} />
    </div>
    <p className="empty-title h5">No rule selected</p>
    <p className="empty-subtitle">Select a rule from the menu on left</p>
    <div className="empty-action">
    </div>
  </div>
);

export default class extends React.Component {
  constructor() {
    super();
    this.state = { modalVisible: false };
  }
  onClick(rule) {
    this.setState({ selected: rule });
  }
  
  onNewRule() {
    this.toggleModal()
  }

  onConfirmNewRuleIsLegacy() {
    const groupId = getGroup().id;
    this.setState({
      selected: {
        id: -Date.now(),
        usesLlm: false,
        description: '',
        trigger: 'preciseListMatch',
        action: 'notify',
        templates: [{ sms: '', push: '', email: '' }, { sms: '', push: '', email: '' },
        { sms: '', push: '', email: '' }, { sms: '', push: '', email: '' }],
        notifications: [],
        triggerConfig: {},
        groupId: groupId,
        canEdit: true,
        llmConfig: undefined,
      },
      modalVisible: false,
    });
  }
  onConfirmNewRuleIsLLM(rules) {
    const groupId = getGroup().id;
    let llmConfig = undefined;
    for (const rule of rules) { 
      if (rule.llmConfig) { 
        llmConfig = rule.llmConfig;
        break;
      }
    }
    llmConfig = llmConfig || { 
      llmName: "ibmWatsonX",
      triggerType: "offensive", 
      projectId: "",
      apiKey: "",
      serviceUrl: "",
      modelId: "ibm/granite-3-8b-instruct",
    }
    this.setState({
      selected: {
        id: -Date.now(),
        description: '',
        usesLlm: true,
        trigger: 'llm',
        action: 'notify',
        templates: [{ sms: '', push: '', email: '' }, { sms: '', push: '', email: '' },
        { sms: '', push: '', email: '' }, { sms: '', push: '', email: '' }],
        notifications: [],
        triggerConfig: {},
        groupId: groupId,
        canEdit: true,
        llmConfig,
      },
      modalVisible: false,
    });
  }


  onUpdateLLMTriggerType(triggerType) {
    this.setState({
      selected: {
        ...this.state.selected,
        llmConfig: {
          ...this.state.selected.llmConfig,
          triggerType,
        }
      }
    })
  };

  onUpdateLLMConfig(llmConfig) {
    this.setState({
      selected: {
        ...this.state.selected,
        llmConfig,
      }
    })
  };

  async refresh(refetch) { 
    const { data } =  await refetch();
    this.setState({selected: data.rules[data.rules.length - 1]});
  }


  isSelected(item, selected) {
    return (selected && item.id === selected.id) ? 'active' : '';
  }
  toggleModal() {
    this.setState(prev => ({...prev, modalVisible: !this.state.modalVisible}))
  }
  render() {
    const { selected, modalVisible } = this.state;
    return (
      <QueryHandler query={queryRules} variables={{ groupId: getGroup().id }} handler={
        (data, refetch) => {
          if (!this.state.selected) { // TODO: move this, debugging only
            require('setimmediate');
            if (data.rules.length > 0) {
              global.setImmediate(() => this.setState({ selected: data.rules[0] }));
            }
          }
          const items = data.rules.map((i, k) => (
            <div className="menu-item" key={k}>
              <A
                className={`${this.isSelected(i, selected)}`}
                onClick={this.onClick.bind(this, i)}>
                
                <div className={`match_${i.description.replaceAll(" ", "_").toLowerCase()} rule_page_alignment`} />
                {i.llmConfig && ":"}{i.description}
              </A>
            </div>
          ));
          return (
            <Container>
              {modalVisible &&
               <LLMDialog 
                onConfirmNewRuleIsLegacy={this.onConfirmNewRuleIsLegacy.bind(this)} 
                msg="Should the new rule use LLM (Large Language Model) AI technology for processing text?" 
                onConfirmNewRuleIsLLM={this.onConfirmNewRuleIsLLM.bind(this, data.rules)}
                onCancel={this.toggleModal.bind(this)}
                />}
              <Menu className="menu">
                <div className="h5">Your Rules</div>
                {items}
                <NewRuleBtn id="new-rule" onClick={this.onNewRule.bind(this)} className="btn my-2  btn-lg" aria-label="Create a new rule for this group">Add New Rule</NewRuleBtn>
              </Menu>
              <div className="container">
                <div className="columns">
                  <div className="column">
                    {!selected ?
                      <NoRuleSelected /> :
                      <Edit
                        onNewLegacyRule={this.onConfirmNewRuleIsLegacy.bind(this)}
                        item={selected}
                        administrators={data.group.administrators}
                        key={selected.id}
                        onUpdate={selected => this.setState({ selected })}
                        refresh={this.refresh.bind(this, refetch)}
                      />
                    }
                  </div>
                </div>
              </div>
            </Container>
          );
        }
      } />
    )
  }
}


export const LLMDialog = ({ msg, onConfirmNewRuleIsLegacy, onConfirmNewRuleIsLLM, onCancel }) => {
 
  return (
      <div className={`modal active`} id='modal-id'>
        <div className='modal-container modal-lg'>
          <div className='modal-header'>
            <div className='modal-title h5'>Use an LLM for New Rule?</div>
          </div>
          <div className='modal-body'>
            <h5></h5>
            <p>{msg}</p>
          </div>
          <div className='modal-footer'>
          <button type='button' onClick={onConfirmNewRuleIsLLM} className='btn btn-primary btn-link btn-xlg' aria-label='Choose LLM and close dialog window'>
              Use LLM
            </button>
          <button type='button' onClick={onConfirmNewRuleIsLegacy} className='btn btn-primary btn-link btn-xlg' aria-label='Choose legacy tech and close dialog window'>
            Use legacy tech
          </button>
          <button type='button' onClick={onCancel} className='btn btn-primary btn-link btn-xlg' aria-label='Do not save changes and close dialog window'>
            Cancel
          </button>
          </div>
        </div>
      </div>
  );
};